export const colors = {
    darkBlue: '#022550',
    Purple_Graph2: '#C5C4FF',
    Green3: '#D8F3D2',
    Green: '#5EBA47',
    LightBlue: '#D5E6FE',
    td_blue_dark: '#3D89DF',
    td_green_dark: '#40B869',
    td_orange_dark: '#F77A4A',
    td_red_dark: '#DD524D',
    td_yellow_dark: '#F5B546',
    white: '#FFF',
    black: '#000',
    grayOne: '#888',
    grayTwo: '#666',
    grayThree: '#EEEEEE',
    grayFour: '#A2A2A2',
    lightGrayOne: '#ddd',
    whiteOne: '#f5efef',
    blueOne: '#176DE8',
    blueTwo: '#0561D1',
    blueThree: '#D5E6FE',
    Light_Blue_PRI: '#3185FC',
    footerText:'#999',
    error: '#FF4B4B',
    success: "#3CC326",
    graySix: "#ABABAB",
    background: '#F2F3F6'
  };
  