import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { Signals } from "./page/Signals/SignalsQuery";
import { Admin } from "./page/Admin/AdminQuery";
import authReducer from "./page/Login/authSlice";
import authApi from "./page/Login/authApiSlice";
import headerApi from './feature/Header/HeaderQuery';
import configReducer from "./feature/Header/ConfigSlice";
import layoutReducer from "./feature/Layout/LayoutSlice";
import { Tenant } from "./page/Tenant/TenantQuery";
import { Users } from "./page/Users/UsersQuery";
import { TenantCustomers } from "./page/ClientDetails/slice/TenantsCustomerQuery";
import { TenantNpsQuery } from "./page/ClientDetails/slice/TenantNpsQuery";
import { TenantCsatQuery } from "./page/ClientDetails/slice/TenantCsatQuery";
import { CHIQuery } from "./page/ClientDetails/slice/CHIQuery";
import { InsightsQuery } from "./page/ClientDetails/slice/InsightsQuery";
import { InsightHomeQuery } from "./page/Home/InsightHomeQuery";
import { SurveyCrudQuery } from "./page/ClientDetails/slice/SurveyCrudQuery";
import { CustomerSegmentCrudQuery } from "./page/ClientDetails/slice/CustomerSegmentCrudQuery";
import { SignalsCommonCrudQuery } from "./page/ClientDetails/slice/SignalsCommonCrudQuery";
import { SignalsRestQuery } from "./page/Signals/SignalsRestQuery";
import { ContentEffectivenessCrudQuery } from "./page/ClientDetails/slice/ContentEffectivenessCrudQuery";
import { CustomerSummaryQuery } from "./page/CustomerSummary/CustomerSummaryQuery";
import customerReducer from './page/ClientDetails/slice/CustomerSlice';

const rootReducer = combineReducers({
  [Signals.reducerPath]: Signals.reducer,
  [Admin.reducerPath]: Admin.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [headerApi.reducerPath]: headerApi.reducer,
  [Tenant.reducerPath]: Tenant.reducer,
  [Users.reducerPath]: Users.reducer,
  [TenantCustomers.reducerPath]: TenantCustomers.reducer,
  [TenantNpsQuery.reducerPath]: TenantNpsQuery.reducer,
  [TenantCsatQuery.reducerPath]: TenantCsatQuery.reducer,
  [CHIQuery.reducerPath]: CHIQuery.reducer,
  [InsightsQuery.reducerPath]: InsightsQuery.reducer,
  [InsightHomeQuery.reducerPath]: InsightHomeQuery.reducer,
  [SurveyCrudQuery.reducerPath]: SurveyCrudQuery.reducer,
  [CustomerSegmentCrudQuery.reducerPath]: CustomerSegmentCrudQuery.reducer,
  [SignalsCommonCrudQuery.reducerPath]: SignalsCommonCrudQuery.reducer,
  [SignalsRestQuery.reducerPath]: SignalsRestQuery.reducer,
  [ContentEffectivenessCrudQuery.reducerPath]: ContentEffectivenessCrudQuery.reducer,
  [CustomerSummaryQuery.reducerPath]: CustomerSummaryQuery.reducer,
  auth: authReducer,
  config: configReducer,
  layout: layoutReducer,
  customer: customerReducer,
});

const apiMiddlewares = [
  Signals.middleware,
  Admin.middleware,
  authApi.middleware,
  headerApi.middleware,
  Tenant.middleware,
  Users.middleware,
  TenantCustomers.middleware,
  TenantNpsQuery.middleware,
  TenantCsatQuery.middleware,
  CHIQuery.middleware,
  InsightsQuery.middleware,
  InsightHomeQuery.middleware,
  SurveyCrudQuery.middleware,
  CustomerSegmentCrudQuery.middleware,
  SignalsCommonCrudQuery.middleware,
  SignalsRestQuery.middleware,
  ContentEffectivenessCrudQuery.middleware,
  CustomerSummaryQuery.middleware,
];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(...apiMiddlewares),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
