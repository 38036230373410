export const literals = {
  signals: "SIGNALS",
  viewAll: "View All",
  nps: "NPS",
  csat: "CSAT",
  totalCustomers: "Total Customers",
  viewSummary: "View Summary",
  topThreeLoyal: "Top 3 Loyal Customers",
  topThreeAtRisk: "Top 3 At-Risk",
  chi:"CHI",
  netBenefits: "NET BENEFITS TO DATE",
  valueCaptured: "Value Captured",
  roi: "ROI",
  insights: "INSIGHTS",
  execution: "EXECUTION",
  customerSummary: "Customers Summary",
  npsTrend:"NPS Trend",
  npsAndCsatTrend: "NPS and CSAT Trend",
  surveysPlanned: "Surveys Planned",
  surveys: "Surveys",
  lastQuarterSurvey: "Last Quarter Surveys",
  overallCsatTrend:"Overall CSAT Trend",
  csatTouchPoints: "CSAT Touchpoints",
  email: "Email",
  password: "Password",
  forgotPassword: "Forgot password?",
  submit: "Submit",
  newUser:"New user? ",
  clickHere: "Click here",
  toCreateAccount: " to create an account.",
  poweredBy: "powered by ",
  upload: "Upload",
  clientName: "CLIENT NAME",
  accountManager: "ACCOUNT MANAGER",
  onboardDate: "ONBOARD DATE",
  valCaptured: "VALUE CAPTURED",
  fileUploaded: "FILE UPLOADED",
  fieldOne: "clientName",
  fieldTwo: "accountManager",
  fieldThree: "onboardDate",
  fieldFour: "valueCaptured",
  fieldFive: "fileUploaded",
  fieldSix: "upload",
  uploadTypeError: "Invalid file type. Please upload an Excel file.",
  uploadHeader: "Upload and attach files",
  supportedFormats: "Supported formats: xls, xlsx",
  clickToUpload: "Click to upload or drag and drop.",
  maximumFile: "Maximum file size 50 MB.",
  failed: "FAILED",
  fileName: "FILE NAME",
  status: "STATUS",
  fieldSeven: "fileName",
  fieldEight:"status",
  errors: "Errors",
  field: "Field",
  rejectedValue: "Rejected Value",
  message: "Message",
  returned: "Returned",
  notReturned: "Not Returned",
  up: "Up",
  down: "Down",
  responseRate: "Response Rate",
  sinceLastMonth: "since last month",
  totalSurveys: "Total Surveys",
  loyal:"loyal",
  neutral: "neutral",
  uploadDate: "UPLOAD DATE",
  userManagement: "User Management",
  about: "About",
  ClientDetails: "Client Details",
  uploadFile: "UPLOAD FILE",
  customerEffortScore: "Customer Effort Score",
  logout: 'Logout',
  privacyPloicy: 'Privacy Policy',
  termsAndConditions: 'Terms & conditions',
  tenantLiterals: {
    tenantId: "ID",
    tenantName: "NAME",
    address: "ADDRESS",
    city: "CITY",
    stateProvince: "STATE PROVINCE",
    postalCode: "POSTAL CODE",
    industry: "INDUSTRY",
    tenantIdField : "id",
    tenantNameField : "name",
    addressField : "address",
    cityField : "city",
    stateProvinceField : "stateProvince",
    postalCodeField : "postalCode",
    industryField : "industry",
    phone: "PHONE",
    url: "URL",
    revenue: "REVENUE",
    phoneField : "phone",
    urlField : "url",
    revenueField : "revenue"
  },
  userLiterals:{
    username: "USERNAME",
    password: "PASSWORD",
    role: "ROLE",
    firstName: "FIRST NAME",
    lastName: "LAST NAME",
    usernameField: "username",
    passwordField: "password",
    roleField: "role",
    firstNameField: "firstName",
    lastNameField: "lastName",
    userIdField: "id",
    userId: "USER ID"
  },
  newlyUncovered: 'Newly Uncovered',
  readyForAction: 'Ready for action',
  totalInsights: 'Total Insights',
  promotedInsights: 'Promoted Insights',
  detailsOfPromotedInsights: 'Details of promoted insights',
  rankings: 'Rankings',
  unlockNewFeatures:'Unlock New Features',
  comingSoon:'Coming Soon',
  contactSales: 'Contact Sales',
  noDataAdded: 'No Data Added',
  contentCsat: 'CONTENT CSAT',
  interceptResponseRates: 'Intercept Response Rates',
  contentEffectiveness: 'CONTENT EFFECTIVENESS',
  segmentName: 'Segment Name',
  addCustomerToSegment: 'Add customer to a segment',
  newSegmentCreated: 'New segment created',
  customerSegmentUpdated: 'Customer Segment Updated',
  somethingWentWrong: 'Something went wrong !',
  dataAddsuccess: 'Data added successfully',
  dataUpdatesuccess: 'Data updated successfully',
};
